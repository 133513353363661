export function objectIsEmpty(obj) {
  const _obj = obj;

  for (var prop in _obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) return false;
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function getGradientStopsFromSVG(gradientRef) {
  const stops = gradientRef.querySelectorAll("stop");
  const gradientStops = [];

  // Iterate over the stop elements and extract the offset and color
  stops.forEach((stop) => {
    const offset = stop.getAttribute("offset");
    const color = stop.style.stopColor;
    gradientStops.push({ offset: parseFloat(offset), color: color });
  });

  return gradientStops;
}

export function getColorAtGradientOffset(stops, offset) {
  for (var i = 1; i < stops.length; i++) {
    if (offset < stops[i].offset) {
      var startStop = stops[i - 1];
      var endStop = stops[i];
      // var localOffset =
      //   (offset - startStop.offset) / (endStop.offset - startStop.offset);
      // console.log("localOffset", localOffset);
      var startColor = rgbToArray(startStop.color);
      var endColor = rgbToArray(endStop.color);
      var color = interpolateColor(startColor, endColor, 0.5);
      return rgbToHex(color[0], color[1], color[2]);
    }
  }

  // Offset is after last stop
  return stops[stops.length - 1].color;
}

function interpolateColor(color1, color2, factor) {
  var result = color1.slice();
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}

function rgbToArray(rgbString) {
  const matches = rgbString.match(/\d+/g); // Find all groups of digits
  if (matches && matches.length === 3) {
    return matches.map(Number); // Convert the strings to numbers
  } else {
    throw new Error("Invalid RGB format");
  }
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
