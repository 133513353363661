<template>
  <div class="mb-12" style="width: 85%; margin: 0 auto">
    <div v-if="loading" class="d-flex align-center justify-center">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>

    <svg
      version="1.1"
      class="svg-element"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 385.4 440"
      enable-background="new 0 0 385.4 440"
      xml:space="preserve"
      :style="loading ? 'opacity: 0' : 'opacity: 1'"
    >
      <svg:style type="text/css">
        @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600");
        text {
          font-family: "Inter";
          font-weight: bold;
        }
        .bold-text {
          font-weight: bold;
        }
      </svg:style>

      <!-- <g id="improvedAvatar" transform="matrix(1 0 0 1 -116  145)"> -->
      <text
        id="improvedScoreValue"
        transform="matrix(1 0 0 1 190 327)"
        fill="#FFFFFF"
        font-family="'Inter-Bold'"
        font-size="77.2388px"
        class="bold-text"
      >
        0%
      </text>
      <text
        ref="improvedRiskText"
        transform="matrix(1 0 0 1 32 410)"
        :fill="pdfType === 'print' ? '#4188EC' : 'rgba(255,255,255,1)'"
        font-family="'Inter-Bold'"
        font-size="30px"
        class="bold-text"
        opacity="1"
      >
        {{ improvedRiskText }}
      </text>
      <rect
        ref="improvedProspectAvatarBox"
        x="0"
        y="220"
        fill="rgba(255,255,255,0.08)"
        width="385.4"
        height="215"
        stroke="rgba(255,255,255,0.25)"
        stroke-width="1px"
        rx="5px"
        ry="5px"
        stroke-linejoin="round"
      />
      <g transform="matrix(1 0 0 1 10 167)">
        <component
          :is="prospectAvatarComponent"
          :key="gender + '-' + age.toString()"
          :ringColor="improvedProspectAvatarRingColor"
          @hook:mounted="checkComponentsLoaded('improvedAvatarComponent')"
        />
      </g>
      <!-- </g> -->
      <text
        ref="currentRiskText"
        transform="matrix(1 0 0 1 90 45)"
        :fill="pdfType === 'print' ? '#4188EC' : '#A4D6EC'"
        font-family="'Inter-Bold'"
        font-size="30px"
        class="bold-text"
        opacity="1"
      >
        {{ currentRiskText }}
      </text>
      <text
        id="prospectScoreValue"
        transform="matrix(1 0 0 1 185.2436 156.3388)"
        :fill="pdfType === 'print' ? '#4188EC' : '#A4D6EC'"
        font-family="'Inter-Bold'"
        font-size="77.2388px"
        class="bold-text"
      >
        0%
      </text>
      <g id="prospectAvatar" transform="matrix(1 0 0 1 5 0)">
        <component
          :is="prospectAvatarComponent"
          :key="gender + '-' + age.toString()"
          :ringColor="prospectAvatarRingColor"
          @hook:mounted="checkComponentsLoaded('prospectAvatarComponent')"
        />
      </g>
      <linearGradient
        ref="prospectBarGradient"
        id="prospectBarGradient_00000160153878589513903800000005087199854486771598_"
        gradientUnits="userSpaceOnUse"
        x1="-248820.1094"
        y1="35.2507"
        x2="-24740.834"
        y2="35.2507"
        gradientTransform="matrix(-1.719556e-03 0 0 1 -42.3785 0)"
      >
        <stop offset="0" style="stop-color: #e42320" />
        <stop offset="0.3821" style="stop-color: #f8af3c" />
        <stop offset="0.4892" style="stop-color: #f8ce35" />
        <stop offset="0.5822" style="stop-color: #f8e131" />
        <stop offset="0.6462" style="stop-color: #f8e82f" />
        <stop offset="1" style="stop-color: #7fbb48" />
      </linearGradient>
      <!-- <rect
        x="0.2"
        y="0"
        fill="url(#prospectBarGradient_00000160153878589513903800000005087199854486771598_)"
        width="385.3"
        height="22.4"
      /> -->
      <!--  <polygon
        id="indicator"
        opacity="0"
        fill="#FFFFFF"
        points="0.5,29.9 -5.4,19.6 -11.4,9.4 0.5,9.4 12.3,9.4 6.4,19.6 "
      /> -->
    </svg>
  </div>
</template>

<script>
import gsap from "gsap";
import { getColorAtGradientOffset, getGradientStopsFromSVG } from "@/helpers";

export default {
  props: {
    prospectScore: {
      type: [Number, String],
      default: 81,
      required: true,
    },
    pdfType: {
      type: String,
      required: false,
      default: null,
    },
    age: {
      type: [Number, String],
      default: 40,
      required: true,
    },
    gender: {
      type: String,
      default: "Male",
      required: true,
    },
    currentRiskText: {
      type: String,
      default: "",
      required: false,
    },
    improvedRiskText: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      loading: true,
      duration: 1.5,
      ease: "ease-out",
      componentsLoaded: {
        prospectAvatarLoaded: false,
        improvedAvatarLoaded: false,
      },
      improvedProspectAvatarRingColor: null,
      prospectAvatarRingColor: null,
    };
  },
  computed: {
    prospectAvatarComponent() {
      let avatarAge;

      if (this.age <= 55) {
        avatarAge = "55";
      } else if (this.age >= 56) {
        avatarAge = "56";
      }
      let avatarComponentName = this.gender + avatarAge + "Avatar";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}Mobile.vue`
        );
    },
    improvedAvatarComponent() {
      let avatarComponentName = this.gender + "improvedAvatarMobile";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}.vue`
        );
    },
  },
  watch: {
    componentsLoaded: {
      handler(val) {
        if (val.prospectAvatarLoaded) {
          this.loading = false;
          this.runAnimations();
        }
      },
      deep: true,
    },
  },
  mounted() {
    const linearGradientStops = getGradientStopsFromSVG(
      this.$refs.prospectBarGradient
    );
    console.log("linearGradientStops", linearGradientStops);

    this.prospectAvatarRingColor = getColorAtGradientOffset(
      linearGradientStops,
      (100 - this.prospectScore) / 100
    );

    this.improvedProspectAvatarRingColor = getColorAtGradientOffset(
      linearGradientStops,
      (100 - this.prospectScore * 0.33) / 100
    );
  },
  methods: {
    checkComponentsLoaded(comp) {
      if (comp === "prospectAvatarComponent") {
        this.componentsLoaded.prospectAvatarLoaded = true;
      } else {
        this.componentsLoaded.improvedAvatarLoaded = true;
      }
    },
    runAnimations() {
      // const indicator = document.getElementById("indicator");
      const prospect_avatar = document.getElementById("prospectAvatar");
      // const prospect_gradient_bar = this.$refs.prospectBarGradient;

      // const prospect_bar_width = prospect_gradient_bar.getBBox().width;
      const prospect_score_text = document.getElementById("prospectScoreValue");
      // const prospect_indicator_location =
      //   (parseInt(prospect_bar_width) * this.prospectScore) / 100;

      const improved_prospect_score = Math.round(this.prospectScore * 0.33);
      const improved_score_text = document.getElementById("improvedScoreValue");
      const improved_avatar = document.getElementById("improvedAvatar");

      gsap.set([prospect_avatar, improved_avatar], {
        scale: 0,
        transformOrigin: "50% 50%",
      });

      const prospectTL = gsap.timeline();

      prospectTL.to([prospect_avatar, improved_avatar], {
        scale: 1,
        transformOrigin: "50% 50%",
        duration: 0.25,
        ease: "back.out(1.7)",
      });
      // .to(indicator, {
      //   opacity: 1,
      //   duration: 0.5,
      //   ease: this.ease,
      // })
      // .to(
      //   indicator,
      //   {
      //     x: prospect_indicator_location,
      //     duration: this.duration - 1,
      //     ease: this.ease,
      //   },
      //   "-=.5"
      // );

      const prospectValueTarget = { val: 0 };
      gsap.to(
        prospectValueTarget,
        {
          val: this.prospectScore,
          duration: this.duration + 0.45,
          ease: this.ease,
          onUpdate: function () {
            prospect_score_text.innerHTML =
              prospectValueTarget.val.toFixed(0) + "%";
          },
        },
        0
      );

      const improvedValueTarget = { val: 0 };
      gsap.to(
        improvedValueTarget,
        {
          val: improved_prospect_score,
          duration: this.duration + 0.45,
          ease: this.ease,
          onUpdate: function () {
            improved_score_text.innerHTML =
              improvedValueTarget.val.toFixed(0) + "%";
          },
        },
        0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-element {
  overflow: visible;
}
</style>
