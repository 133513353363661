<template>
  <div v-if="displayValue" style="position: relative">
    <div v-if="isEditing" class="mb-4 text-center">
      <v-btn color="accent" @click="handleShowGraphicEditorDialog"
        >Edit Text</v-btn
      >
    </div>
    <template v-if="!loading">
      <ReportImproveAnimation
        v-if="!isMobile"
        :prospectScore="displayValue.score"
        :age="displayValue.age"
        :gender="displayValue.gender"
        :currentRiskText="displayValue.currentRiskText"
        :improvedRiskText="displayValue.improvedRiskText"
        :pdfType="pdfType"
      />

      <ReportImproveAnimationMobile
        v-if="isMobile"
        :prospectScore="displayValue.score"
        :age="displayValue.age"
        :gender="displayValue.gender"
        :currentRiskText="displayValue.currentRiskText"
        :improvedRiskText="displayValue.improvedRiskText"
        :pdfType="pdfType"
      />
    </template>
    <v-dialog
      v-if="isEditing"
      v-model="showGraphicEditorDialog"
      width="500px"
      max-width="800px"
    >
      <v-card tile>
        <v-card-title class="mb-6 text-h6">Edit Graphic Content</v-card-title>
        <v-card-text>
          <v-text-field
            :value="displayValue.currentRiskText"
            label="Current Risk Text"
            outlined
            hint="Use a small amount of text"
            persistent-hint
            class="mb-4"
            @input="emitUpdate($event, 'currentRiskText')"
          ></v-text-field>
          <v-text-field
            :value="displayValue.improvedRiskText"
            label="Improved Risk Text"
            outlined
            hint="Use a small amount of text"
            persistent-hint
            @input="emitUpdate($event, 'improvedRiskText')"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="handleCloseGraphicEditorDialog"
            color="error"
            class="mr-4"
            >Cancel</v-btn
          >
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="handleOk"
            color="primary"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReportImproveAnimation from "@/components/ReportImproveAnimation.vue";
import ReportImproveAnimationMobile from "@/components/ReportImproveAnimationMobile.vue";

export default {
  components: {
    ReportImproveAnimation,
    ReportImproveAnimationMobile,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isEditing: Boolean,
    isMobile: Boolean,
    pdfType: String,
  },
  data() {
    return {
      showGraphicEditorDialog: false,
      hasGraphicEditorData: false,
      loading: false,
      cache: null,
      displayValue: null,
      displayText: {
        currentRiskText: "Current Risk",
        improvedRiskText: "We Can Get You Here",
      },
      serverValue: {
        score: "{{score}}",
        age: "{{current_age}}",
        gender: "{{gender}}",
      },
    };
  },
  mounted() {
    if (this.value.currentRiskText) {
      this.displayText.currentRiskText = this.value.currentRiskText;
    }

    if (this.value.improvedRiskText) {
      this.displayText.improvedRiskText = this.value.improvedRiskText;
    }

    if (this.isEditing) {
      this.displayValue = {
        ...this.displayText,
        score: 65,
        age: 45,
        gender: "Female",
      };

      // To set the server data with dynamic variables that will be used in the actual report display
      // While mock data is shown during editing b/c there is not actual data to display
      const serverData = {
        ...this.displayText,
        ...this.serverValue,
      };

      this.$emit("update:value", Object.assign({}, serverData));
    } else {
      this.displayValue = this.value;
    }
  },
  methods: {
    emitUpdate(e, property) {
      this.value[property] = e;
      this.$emit("update:value", Object.assign({}, this.value));
      this.displayValue[property] = e;
    },
    handleOk() {
      this.loading = true;
      this.showGraphicEditorDialog = false;
      this.$nextTick(() => {
        this.loading = false;
        this.cache = null;
      });
    },
    handleShowGraphicEditorDialog() {
      this.cache = Object.assign({}, this.value);
      this.showGraphicEditorDialog = true;
    },
    handleCloseGraphicEditorDialog() {
      // reset forms if dialog is canceled with cached data that is set when dialog is opened
      this.$emit("update:value", Object.assign({}, this.cache));
      this.showGraphicEditorDialog = false;

      this.$nextTick(() => {
        this.cache = null;
      });
    },
  },
};
</script>
