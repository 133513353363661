<template>
  <div class="rs-improve">
    <svg
      version="1.1"
      ref="riskScoreImproveSvg"
      class="rs-improve__svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 489 146.4"
      enable-background="new 0 0 489 146.4"
      xml:space="preserve"
    >
      <svg:style type="text/css">
        @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600");
        text {
          font-family: "Inter";
          font-weight: bold;
        }
        .bold-text {
          font-weight: bold;
        }
      </svg:style>
      <g ref="mainGrid" opacity="0.7">
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="0.1"
          y1="127.6"
          x2="0.1"
          y2="59.9"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="488.9"
          y1="127.6"
          x2="488.9"
          y2="59.9"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="440"
          y1="59.9"
          x2="440"
          y2="127.6"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="391.1"
          y1="59.9"
          x2="391.1"
          y2="127.6"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="342.2"
          y1="59.9"
          x2="342.2"
          y2="127.6"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="293.4"
          y1="59.9"
          x2="293.4"
          y2="127.6"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="244.5"
          y1="59.9"
          x2="244.5"
          y2="127.6"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="195.6"
          y1="59.9"
          x2="195.6"
          y2="127.6"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="146.7"
          y1="59.9"
          x2="146.7"
          y2="127.6"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="97.9"
          y1="59.9"
          x2="97.9"
          y2="127.6"
        />
        <line
          fill="none"
          :stroke="pdfType === 'print' ? '#333' : '#FFFFFF'"
          stroke-width="0.15"
          stroke-miterlimit="10"
          x1="49"
          y1="59.9"
          x2="49"
          y2="127.6"
        />
      </g>

      <linearGradient
        ref="prospectBarGradient"
        :id="`prospectBarGradient_${uniqueGradientIdSuffix}`"
        gradientUnits="userSpaceOnUse"
        x1="-284361.0625"
        y1="44.8468"
        x2="1.434529e-02"
        y2="44.8468"
        gradientTransform="matrix(-1.719556e-03 0 0 1 1.436996e-02 0)"
      >
        <stop offset="0" style="stop-color: #e42320" />
        <stop offset="0.3821" style="stop-color: #f8af3c" />
        <stop offset="0.4892" style="stop-color: #f8ce35" />
        <stop offset="0.5822" style="stop-color: #f8e131" />
        <stop offset="0.6462" style="stop-color: #f8e82f" />
        <stop offset="1" style="stop-color: #7fbb48" />
      </linearGradient>
      <rect
        ref="prospectBar"
        x="0"
        y="29.8"
        :fill="`url(#prospectBarGradient_${uniqueGradientIdSuffix})`"
        width="489"
        height="30.1"
      />
      <line
        ref="prospectMask"
        class="prospectMask"
        fill="#F9E7DD"
        stroke="#F9E7DD"
        stroke-width="30"
        stroke-miterlimit="10"
        x1="0"
        y1="44.9"
        x2="489"
        y2="44.9"
      />
      <g ref="prospectGrid">
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="0.1"
          y1="59.9"
          x2="0.1"
          y2="30.8"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="488.9"
          y1="59.9"
          x2="488.9"
          y2="30.8"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="440"
          y1="30.8"
          x2="440"
          y2="59.9"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="391.1"
          y1="30.8"
          x2="391.1"
          y2="59.9"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="342.2"
          y1="30.8"
          x2="342.2"
          y2="59.9"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="293.4"
          y1="30.8"
          x2="293.4"
          y2="59.9"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="244.5"
          y1="30.8"
          x2="244.5"
          y2="59.9"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="195.6"
          y1="30.8"
          x2="195.6"
          y2="59.9"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="146.8"
          y1="30.8"
          x2="146.8"
          y2="59.9"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="97.9"
          y1="30.8"
          x2="97.9"
          y2="59.9"
        />
        <line
          fill="none"
          stroke="#9C9B9B"
          stroke-width="0.2483"
          stroke-miterlimit="10"
          x1="49"
          y1="30.8"
          x2="49"
          y2="59.9"
        />
      </g>
      <g ref="improvedProspectAvatar" opacity="0">
        <rect
          ref="improvedProspectAvatarBox"
          x="-32"
          y="-5"
          fill="rgba(255,255,255,0.08)"
          width="115"
          height="115"
          stroke="rgba(255,255,255,0.25)"
          stroke-width="1px"
          rx="5px"
          ry="5px"
          stroke-linejoin="round"
        />
        <component
          :is="improvedProspectAvatarComponent"
          :key="gender + '-' + age.toString()"
          :ringColor="improvedProspectAvatarRingColor"
        />

        <text
          ref="improvedProspectScoreValue"
          transform="matrix(1 0 0 1 7.2353 19)"
          :fill="pdfType === 'print' ? '#4188EC' : 'rgba(255,255,255,1)'"
          font-family="'Inter-Bold'"
          font-size="22px"
          class="bold-text"
        >
          0%
        </text>
        <text
          ref="improvedRiskText"
          transform="matrix(1 0 0 1 -25 90)"
          :fill="pdfType === 'print' ? '#4188EC' : 'rgba(255,255,255,1)'"
          font-family="'Inter-Bold'"
          font-size="10px"
          class="bold-text"
          opacity="0"
        >
          {{ improvedRiskText }}
        </text>
      </g>
      <g ref="prospectAvatar" opacity="0">
        <component
          :is="prospectAvatarComponent"
          :key="gender + '-' + age.toString()"
          :ringColor="prospectAvatarRingColor"
        />

        <text
          ref="prospectScoreValue"
          transform="matrix(1 0 0 1 7.2353 19)"
          :fill="pdfType === 'print' ? '#4188EC' : '#A4D6EC'"
          font-family="'Inter-Bold'"
          font-size="22px"
          class="bold-text"
        >
          0%
        </text>
        <text
          ref="currentRiskText"
          transform="matrix(1 0 0 1 0 90)"
          :fill="pdfType === 'print' ? '#4188EC' : '#A4D6EC'"
          font-family="'Inter-Bold'"
          font-size="10px"
          class="bold-text"
          opacity="0"
        >
          {{ currentRiskText }}
        </text>
      </g>
    </svg>

    <!-- PERCENTAGES -->
    <svg
      version="1.1"
      ref="percentagesSvg"
      class="percentages"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 507.2 13.2"
      enable-background="new 0 0 507.2 13.2"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M3.5,9.7C2.9,9.7,2.4,9.5,2,9.2C1.5,8.9,1.2,8.4,1,7.8C0.8,7.2,0.7,6.4,0.7,5.6c0-0.9,0.1-1.6,0.3-2.2
			c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4c0.2,0.6,0.3,1.4,0.3,2.2
			c0,0.9-0.1,1.6-0.3,2.2C5.7,8.4,5.4,8.9,5,9.2S4.1,9.7,3.5,9.7z M3.5,8.8c0.6,0,1-0.3,1.4-0.9c0.3-0.6,0.5-1.4,0.5-2.4
			c0-0.7-0.1-1.3-0.2-1.8C5,3.3,4.8,2.9,4.5,2.6c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9C1.8,3.7,1.6,4.5,1.6,5.6
			c0,0.7,0.1,1.3,0.2,1.8C2,7.8,2.2,8.2,2.5,8.5C2.7,8.7,3.1,8.8,3.5,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M7.9,3.5V3c0-0.3,0.1-0.6,0.2-0.8C8.2,2,8.4,1.8,8.6,1.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2
			c0.2,0.1,0.4,0.3,0.5,0.6C11,2.5,11,2.7,11,3v0.4C11,3.8,11,4,10.8,4.3c-0.1,0.2-0.3,0.4-0.5,0.6C10.1,5,9.8,5.1,9.5,5.1
			C9.1,5.1,8.8,5,8.6,4.9C8.4,4.7,8.2,4.5,8.1,4.3S7.9,3.8,7.9,3.5z M8.2,9.6l5.6-8.1h0.9L9.1,9.6H8.2z M8.7,3v0.4
			c0,0.2,0.1,0.5,0.2,0.7C9,4.3,9.2,4.4,9.5,4.4c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3c0-0.2-0.1-0.5-0.2-0.7
			C9.9,2.2,9.7,2.1,9.5,2.1C9.2,2.1,9,2.2,8.9,2.4C8.7,2.6,8.7,2.8,8.7,3z M11.9,8.1V7.7c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6
			C12.9,6.1,13.1,6,13.5,6c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8
			c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S11.9,8.4,11.9,8.1z M12.7,7.7v0.4
			c0,0.2,0.1,0.5,0.2,0.7S13.2,9,13.5,9c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7
			c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C12.8,7.2,12.7,7.4,12.7,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M50.9,1.5v8.1h-1V2.5h0l-2,1.3v-1l2-1.3H50.9z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M55.9,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2
			c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4c0.2,0.6,0.3,1.4,0.3,2.2
			c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4S56.5,9.7,55.9,9.7z M55.9,8.8c0.6,0,1-0.3,1.4-0.9c0.3-0.6,0.5-1.4,0.5-2.4
			c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9C54.2,3.7,54,4.5,54,5.6
			c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C55.2,8.7,55.5,8.8,55.9,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M60.3,3.5V3c0-0.3,0.1-0.6,0.2-0.8C60.6,2,60.8,1.8,61,1.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2
			C63,1.8,63.1,2,63.3,2.2c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.2-0.9,0.2
			c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S60.3,3.8,60.3,3.5z M60.6,9.6l5.6-8.1h0.9l-5.6,8.1H60.6z M61.1,3v0.4
			c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3c0-0.2-0.1-0.5-0.2-0.7
			c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C61.2,2.6,61.1,2.8,61.1,3z M64.3,8.1V7.7c0-0.3,0.1-0.6,0.2-0.8
			s0.3-0.4,0.5-0.6C65.3,6.1,65.6,6,65.9,6c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4
			c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6
			S64.3,8.4,64.3,8.1z M65.1,7.7v0.4c0,0.2,0.1,0.5,0.2,0.7S65.6,9,65.9,9c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V7.7
			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C65.2,7.2,65.1,7.4,65.1,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M97.4,9.6V8.9L100,6c0.3-0.3,0.6-0.6,0.8-0.9s0.4-0.5,0.5-0.7c0.1-0.2,0.1-0.5,0.1-0.7
			c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
			s-0.2,0.5-0.2,0.8h-0.9c0-0.5,0.1-0.9,0.3-1.2c0.2-0.4,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.3,1.3-0.3c0.5,0,0.9,0.1,1.3,0.3
			c0.4,0.2,0.7,0.5,0.9,0.8s0.3,0.7,0.3,1.1c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.6-0.6,0.9c-0.3,0.3-0.6,0.8-1.1,1.3l-1.8,1.9v0.1
			h3.8v0.9H97.4z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M106.7,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2
			c0-0.9,0.1-1.6,0.3-2.2c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4
			c0.2,0.6,0.3,1.4,0.3,2.2c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4S107.3,9.7,106.7,9.7z M106.7,8.8c0.6,0,1-0.3,1.4-0.9
			c0.3-0.6,0.5-1.4,0.5-2.4c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9
			c-0.3,0.6-0.5,1.4-0.5,2.4c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C106,8.7,106.4,8.8,106.7,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M111.1,3.5V3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2
			c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6
			c-0.2,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S111.1,3.8,111.1,3.5z M111.4,9.6l5.6-8.1h0.9
			l-5.6,8.1H111.4z M112,3v0.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3
			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C112,2.6,112,2.8,112,3z M115.2,8.1V7.7
			c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2
			c-0.2-0.1-0.4-0.3-0.5-0.6S115.2,8.4,115.2,8.1z M116,7.7v0.4c0,0.2,0.1,0.5,0.2,0.7s0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3
			c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C116,7.2,116,7.4,116,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M147.8,9.7c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.7-0.4-1-0.7c-0.2-0.3-0.4-0.7-0.4-1.1h1
			c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.6,0.4s0.5,0.2,0.8,0.2c0.3,0,0.7-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.6-0.5s0.2-0.5,0.2-0.8
			c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.2-0.4-0.4-0.6-0.5s-0.6-0.2-1-0.2H147V5h0.6c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5
			s0.2-0.5,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.8-0.2c-0.3,0-0.5,0.1-0.8,0.2s-0.4,0.2-0.6,0.4
			c-0.2,0.2-0.2,0.4-0.3,0.7h-0.9c0-0.4,0.1-0.8,0.4-1.1c0.2-0.3,0.6-0.6,0.9-0.7c0.4-0.2,0.8-0.3,1.3-0.3c0.5,0,0.9,0.1,1.3,0.3
			s0.6,0.5,0.8,0.8c0.2,0.3,0.3,0.7,0.3,1.1c0,0.5-0.1,0.8-0.4,1.2s-0.6,0.5-1,0.7v0.1c0.5,0.1,0.9,0.3,1.2,0.6
			c0.3,0.3,0.4,0.8,0.4,1.3c0,0.4-0.1,0.8-0.4,1.2s-0.6,0.6-1,0.8S148.4,9.7,147.8,9.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M154.8,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2
			c0-0.9,0.1-1.6,0.3-2.2c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4
			c0.2,0.6,0.3,1.4,0.3,2.2c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4S155.4,9.7,154.8,9.7z M154.8,8.8c0.6,0,1-0.3,1.4-0.9
			c0.3-0.6,0.5-1.4,0.5-2.4c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9
			c-0.3,0.6-0.5,1.4-0.5,2.4c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C154.1,8.7,154.4,8.8,154.8,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M159.2,3.5V3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2
			c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6
			c-0.2,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S159.2,3.8,159.2,3.5z M159.5,9.6l5.6-8.1h0.9
			l-5.6,8.1H159.5z M160,3v0.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3
			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C160.1,2.6,160,2.8,160,3z M163.2,8.1V7.7
			c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2
			c-0.2-0.1-0.4-0.3-0.5-0.6S163.2,8.4,163.2,8.1z M164.1,7.7v0.4c0,0.2,0.1,0.5,0.2,0.7s0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3
			c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3
			C164.1,7.2,164.1,7.4,164.1,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M193,7.9V7.1l3.6-5.6h0.6v1.2h-0.4L194,7v0.1h4.8v0.9H193z M196.8,9.6V7.7V7.3V1.5h0.9v8.1H196.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M203,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2
			c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4c0.2,0.6,0.3,1.4,0.3,2.2
			c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4S203.5,9.7,203,9.7z M203,8.8c0.6,0,1-0.3,1.4-0.9c0.3-0.6,0.5-1.4,0.5-2.4
			c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9c-0.3,0.6-0.5,1.4-0.5,2.4
			c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C202.2,8.7,202.6,8.8,203,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M207.4,3.5V3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2
			c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6
			c-0.2,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S207.4,3.8,207.4,3.5z M207.7,9.6l5.6-8.1h0.9
			l-5.6,8.1H207.7z M208.2,3v0.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3
			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C208.2,2.6,208.2,2.8,208.2,3z M211.4,8.1V7.7
			c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2
			c-0.2-0.1-0.4-0.3-0.5-0.6S211.4,8.4,211.4,8.1z M212.2,7.7v0.4c0,0.2,0.1,0.5,0.2,0.7S212.7,9,213,9c0.3,0,0.5-0.1,0.6-0.3
			c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3
			C212.3,7.2,212.2,7.4,212.2,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M243.8,9.7c-0.5,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.7-0.4-0.9-0.8c-0.2-0.3-0.3-0.7-0.4-1.1h0.9
			c0,0.4,0.2,0.7,0.5,0.9s0.7,0.4,1.1,0.4c0.3,0,0.6-0.1,0.9-0.2s0.5-0.4,0.6-0.6c0.1-0.3,0.2-0.6,0.2-0.9c0-0.4-0.1-0.7-0.2-0.9
			c-0.2-0.3-0.4-0.5-0.6-0.7c-0.3-0.2-0.6-0.2-0.9-0.2c-0.2,0-0.5,0-0.8,0.1c-0.3,0.1-0.5,0.2-0.6,0.3l-0.9-0.1l0.5-4h4.2v0.9h-3.4
			l-0.3,2.4h0c0.2-0.1,0.4-0.2,0.6-0.3s0.5-0.1,0.8-0.1c0.5,0,0.9,0.1,1.3,0.4c0.4,0.2,0.7,0.6,0.9,1c0.2,0.4,0.3,0.9,0.3,1.4
			c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.7-0.9,1C244.8,9.6,244.4,9.7,243.8,9.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M250.7,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2
			c0-0.9,0.1-1.6,0.3-2.2c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4
			c0.2,0.6,0.3,1.4,0.3,2.2c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4S251.3,9.7,250.7,9.7z M250.7,8.8c0.6,0,1-0.3,1.4-0.9
			c0.3-0.6,0.5-1.4,0.5-2.4c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9
			c-0.3,0.6-0.5,1.4-0.5,2.4c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C250,8.7,250.3,8.8,250.7,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M255.1,3.5V3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2
			c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6
			c-0.2,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S255.1,3.8,255.1,3.5z M255.4,9.6l5.6-8.1h0.9
			l-5.6,8.1H255.4z M255.9,3v0.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3
			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C256,2.6,255.9,2.8,255.9,3z M259.1,8.1V7.7
			c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2
			c-0.2-0.1-0.4-0.3-0.5-0.6S259.1,8.4,259.1,8.1z M259.9,7.7v0.4c0,0.2,0.1,0.5,0.2,0.7s0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3
			c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3
			C260,7.2,259.9,7.4,259.9,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M291.8,9.7c-0.3,0-0.7-0.1-1-0.2s-0.6-0.3-0.9-0.6c-0.3-0.3-0.5-0.7-0.7-1.2c-0.2-0.5-0.2-1.1-0.2-1.9
			c0-0.7,0.1-1.4,0.2-1.9c0.1-0.6,0.3-1,0.6-1.4c0.3-0.4,0.6-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,0.9,0.1,1.2,0.3
			c0.4,0.2,0.6,0.4,0.9,0.8c0.2,0.3,0.4,0.7,0.4,1.1h-1c-0.1-0.4-0.3-0.7-0.5-0.9c-0.3-0.2-0.6-0.4-1-0.4c-0.6,0-1.1,0.3-1.5,0.8
			s-0.5,1.3-0.5,2.3h0.1c0.1-0.2,0.3-0.4,0.5-0.6s0.4-0.3,0.7-0.4s0.5-0.1,0.8-0.1c0.5,0,0.9,0.1,1.3,0.3s0.7,0.5,0.9,1
			c0.2,0.4,0.3,0.9,0.3,1.4c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.5,0.7-0.9,1C292.8,9.6,292.3,9.7,291.8,9.7z M291.8,8.8
			c0.3,0,0.6-0.1,0.9-0.2s0.5-0.4,0.6-0.7c0.2-0.3,0.2-0.6,0.2-0.9c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.4-0.5-0.6-0.7
			c-0.3-0.2-0.6-0.2-0.9-0.2c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.6C290,6.5,290,6.8,290,7
			c0,0.3,0.1,0.6,0.2,0.9c0.2,0.3,0.4,0.5,0.6,0.7S291.4,8.8,291.8,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M298.6,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2
			c0-0.9,0.1-1.6,0.3-2.2c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4
			c0.2,0.6,0.3,1.4,0.3,2.2c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4S299.2,9.7,298.6,9.7z M298.6,8.8c0.6,0,1-0.3,1.4-0.9
			c0.3-0.6,0.5-1.4,0.5-2.4c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9
			c-0.3,0.6-0.5,1.4-0.5,2.4c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C297.9,8.7,298.2,8.8,298.6,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M303,3.5V3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2
			c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.2-0.9,0.2
			c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S303,3.8,303,3.5z M303.3,9.6l5.6-8.1h0.9l-5.6,8.1H303.3z M303.9,3v0.4
			c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3c0-0.2-0.1-0.5-0.2-0.7
			c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C303.9,2.6,303.9,2.8,303.9,3z M307.1,8.1V7.7c0-0.3,0.1-0.6,0.2-0.8
			s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4
			c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6
			S307.1,8.4,307.1,8.1z M307.9,7.7v0.4c0,0.2,0.1,0.5,0.2,0.7s0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V7.7
			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C307.9,7.2,307.9,7.4,307.9,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M337.3,9.6l3.6-7.2V2.4h-4.2V1.5h5.2v0.9l-3.6,7.2H337.3z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M346,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2
			c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4c0.2,0.6,0.3,1.4,0.3,2.2
			c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4S346.6,9.7,346,9.7z M346,8.8c0.6,0,1-0.3,1.4-0.9c0.3-0.6,0.5-1.4,0.5-2.4
			c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9c-0.3,0.6-0.5,1.4-0.5,2.4
			c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C345.3,8.7,345.6,8.8,346,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M350.4,3.5V3c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2
			c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6
			c-0.2,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S350.4,3.8,350.4,3.5z M350.7,9.6l5.6-8.1h0.9
			l-5.6,8.1H350.7z M351.2,3v0.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3
			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C351.3,2.6,351.2,2.8,351.2,3z M354.4,8.1V7.7
			c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6
			c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6s-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2
			c-0.2-0.1-0.4-0.3-0.5-0.6S354.4,8.4,354.4,8.1z M355.2,7.7v0.4c0,0.2,0.1,0.5,0.2,0.7S355.7,9,356,9c0.3,0,0.5-0.1,0.6-0.3
			c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3
			C355.3,7.2,355.2,7.4,355.2,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M390.1,9.7c-0.5,0-1-0.1-1.4-0.3s-0.7-0.5-1-0.8c-0.2-0.3-0.3-0.7-0.3-1.2c0-0.3,0.1-0.7,0.2-1
			c0.1-0.3,0.3-0.5,0.6-0.7c0.2-0.2,0.5-0.3,0.8-0.4v0c-0.4-0.1-0.7-0.3-0.9-0.7c-0.2-0.3-0.3-0.7-0.3-1.1c0-0.4,0.1-0.8,0.3-1.1
			c0.2-0.3,0.5-0.6,0.9-0.8s0.8-0.3,1.3-0.3c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.3,0.7,0.3,1.1
			c0,0.4-0.1,0.8-0.3,1.1c-0.2,0.3-0.5,0.6-0.9,0.7v0c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.4,0.4,0.6,0.7c0.1,0.3,0.2,0.6,0.2,1
			c0,0.4-0.1,0.8-0.4,1.2c-0.2,0.3-0.6,0.6-1,0.8S390.6,9.7,390.1,9.7z M390.1,8.8c0.4,0,0.7-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.6-0.5
			s0.2-0.5,0.2-0.8c0-0.3-0.1-0.6-0.2-0.8c-0.2-0.2-0.4-0.4-0.6-0.6c-0.3-0.1-0.6-0.2-0.9-0.2s-0.7,0.1-0.9,0.2
			c-0.3,0.1-0.5,0.3-0.6,0.6c-0.2,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8s0.3,0.4,0.6,0.5S389.7,8.8,390.1,8.8z M390.1,5
			c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.7c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.5-0.5
			c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7
			c0.1,0.2,0.3,0.4,0.5,0.5C389.5,4.9,389.8,5,390.1,5z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M397,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2c0-0.9,0.1-1.6,0.3-2.2
			c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5s1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4c0.2,0.6,0.3,1.4,0.3,2.2
			c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4C398.1,9.5,397.6,9.7,397,9.7z M397,8.8c0.6,0,1-0.3,1.4-0.9
			c0.3-0.6,0.5-1.4,0.5-2.4c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.6,0-1,0.3-1.4,0.9
			c-0.3,0.6-0.5,1.4-0.5,2.4c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C396.2,8.7,396.6,8.8,397,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M401.4,3.5V3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2
			c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.2-0.9,0.2
			c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S401.4,3.8,401.4,3.5z M401.7,9.6l5.6-8.1h0.9l-5.6,8.1H401.7z M402.2,3v0.4
			c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3c0-0.2-0.1-0.5-0.2-0.7
			s-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C402.2,2.6,402.2,2.8,402.2,3z M405.4,8.1V7.7c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6
			c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8
			c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2s-0.4-0.3-0.5-0.6S405.4,8.4,405.4,8.1z M406.2,7.7
			v0.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7
			c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C406.3,7.2,406.2,7.4,406.2,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M437.9,1.4c0.3,0,0.7,0.1,1,0.2s0.6,0.3,0.9,0.6c0.3,0.3,0.5,0.7,0.7,1.2s0.2,1.1,0.2,1.9
			c0,0.7-0.1,1.4-0.2,1.9c-0.1,0.6-0.3,1-0.6,1.4c-0.3,0.4-0.6,0.7-0.9,0.9s-0.8,0.3-1.2,0.3c-0.5,0-0.9-0.1-1.2-0.3
			c-0.4-0.2-0.6-0.4-0.9-0.8c-0.2-0.3-0.4-0.7-0.4-1.1h1c0.1,0.4,0.3,0.7,0.5,0.9c0.3,0.2,0.6,0.4,1,0.4c0.6,0,1.1-0.3,1.5-0.8
			c0.4-0.5,0.5-1.3,0.5-2.3h-0.1c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.4,0.3-0.7,0.4s-0.5,0.1-0.8,0.1c-0.5,0-0.9-0.1-1.3-0.3
			c-0.4-0.2-0.7-0.5-0.9-1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.5,0.1-0.9,0.3-1.4c0.2-0.4,0.5-0.7,0.9-1C436.9,1.5,437.4,1.4,437.9,1.4z
			 M437.9,2.3c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,0.9s0.1,0.6,0.2,0.9c0.1,0.3,0.4,0.5,0.6,0.7
			c0.3,0.2,0.6,0.2,0.9,0.2c0.2,0,0.5,0,0.7-0.1s0.4-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.5,0.1-0.7
			c0-0.3-0.1-0.6-0.2-0.9s-0.4-0.5-0.6-0.7S438.2,2.3,437.9,2.3z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M444.9,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2
			c0-0.9,0.1-1.6,0.3-2.2c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5s1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4
			c0.2,0.6,0.3,1.4,0.3,2.2c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4C446,9.5,445.5,9.7,444.9,9.7z M444.9,8.8
			c0.6,0,1-0.3,1.4-0.9c0.3-0.6,0.5-1.4,0.5-2.4c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4
			c-0.6,0-1,0.3-1.4,0.9c-0.3,0.6-0.5,1.4-0.5,2.4c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C444.2,8.7,444.5,8.8,444.9,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M449.3,3.5V3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2
			c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.2-0.9,0.2
			c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S449.3,3.8,449.3,3.5z M449.6,9.6l5.6-8.1h0.9l-5.6,8.1H449.6z M450.1,3v0.4
			c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3c0-0.2-0.1-0.5-0.2-0.7
			s-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C450.2,2.6,450.1,2.8,450.1,3z M453.3,8.1V7.7c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6
			c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8
			c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2s-0.4-0.3-0.5-0.6S453.3,8.4,453.3,8.1z M454.1,7.7
			v0.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7
			c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C454.2,7.2,454.1,7.4,454.1,7.7z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M483,1.5v8.1h-1V2.5h0l-2,1.3v-1l2-1.3H483z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M487.9,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2
			c0-0.9,0.1-1.6,0.3-2.2c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5s1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4
			c0.2,0.6,0.3,1.4,0.3,2.2c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4C489,9.5,488.5,9.7,487.9,9.7z M487.9,8.8
			c0.6,0,1-0.3,1.4-0.9c0.3-0.6,0.5-1.4,0.5-2.4c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4
			c-0.6,0-1,0.3-1.4,0.9c-0.3,0.6-0.5,1.4-0.5,2.4c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C487.2,8.7,487.5,8.8,487.9,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M494.9,9.7c-0.6,0-1.1-0.2-1.5-0.5c-0.4-0.3-0.7-0.8-1-1.4c-0.2-0.6-0.3-1.4-0.3-2.2
			c0-0.9,0.1-1.6,0.3-2.2c0.2-0.6,0.5-1.1,1-1.4c0.4-0.3,0.9-0.5,1.5-0.5s1.1,0.2,1.5,0.5c0.4,0.3,0.7,0.8,1,1.4
			c0.2,0.6,0.3,1.4,0.3,2.2c0,0.9-0.1,1.6-0.3,2.2c-0.2,0.6-0.5,1.1-1,1.4C496,9.5,495.5,9.7,494.9,9.7z M494.9,8.8
			c0.6,0,1-0.3,1.4-0.9c0.3-0.6,0.5-1.4,0.5-2.4c0-0.7-0.1-1.3-0.2-1.8c-0.1-0.5-0.4-0.9-0.6-1.1c-0.3-0.3-0.6-0.4-1-0.4
			c-0.6,0-1,0.3-1.4,0.9c-0.3,0.6-0.5,1.4-0.5,2.4c0,0.7,0.1,1.3,0.2,1.8c0.1,0.5,0.4,0.9,0.6,1.1C494.2,8.7,494.5,8.8,494.9,8.8z"
          />
          <path
            :fill="pdfType === 'print' ? '#333' : '#FFFFFF'"
            d="M499.3,3.5V3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2
			c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.2-0.9,0.2
			c-0.3,0-0.6-0.1-0.9-0.2c-0.2-0.1-0.4-0.3-0.5-0.6S499.3,3.8,499.3,3.5z M499.6,9.6l5.6-8.1h0.9l-5.6,8.1H499.6z M500.1,3v0.4
			c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V3c0-0.2-0.1-0.5-0.2-0.7
			s-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C500.2,2.6,500.1,2.8,500.1,3z M503.3,8.1V7.7c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6
			c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8v0.4c0,0.3-0.1,0.6-0.2,0.8
			c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2s-0.4-0.3-0.5-0.6S503.3,8.4,503.3,8.1z M504.1,7.7
			v0.4c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.3,0.6,0.3c0.3,0,0.5-0.1,0.6-0.3c0.1-0.2,0.2-0.4,0.2-0.7V7.7c0-0.2-0.1-0.5-0.2-0.7
			c-0.1-0.2-0.3-0.3-0.6-0.3c-0.3,0-0.5,0.1-0.6,0.3C504.2,7.2,504.1,7.4,504.1,7.7z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import gsap from "gsap";
import { getColorAtGradientOffset, getGradientStopsFromSVG } from "@/helpers";

export default {
  props: {
    prospectScore: {
      type: [Number, String],
      default: 10,
      required: true,
    },
    pdfType: {
      type: String,
      required: false,
      default: null,
    },
    age: {
      type: [Number, String],
      default: 40,
      required: true,
    },
    gender: {
      type: String,
      default: "Male",
      required: true,
    },
    currentRiskText: {
      type: String,
      default: "",
      required: false,
    },
    improvedRiskText: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      duration: this.pdfType ? 0 : 1.5,
      ease: "ease-out",
      prospectAvatarRingColor: null,
      improvedProspectAvatarRingColor: null,
    };
  },
  computed: {
    prospectAvatarComponent() {
      let avatarAge;

      if (this.age <= 55) {
        avatarAge = "55";
      } else if (this.age >= 56) {
        avatarAge = "56";
      }
      let avatarComponentName = this.gender + avatarAge + "Avatar";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}.vue`
        );
    },
    improvedProspectAvatarComponent() {
      let avatarAge;

      if (this.age <= 55) {
        avatarAge = "55";
      } else if (this.age >= 56) {
        avatarAge = "56";
      }
      let avatarComponentName = this.gender + avatarAge + "Avatar";

      return () =>
        import(
          `@/components/reportAnimationAvatars/${avatarComponentName}.vue`
        );
    },
    uniqueGradientIdSuffix() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
  },
  mounted() {
    setTimeout(() => {
      // this.setSvgMultilineText();
      this.runAnimations();
      // window.addEventListener("resize", () => {
      //   this.setSvgMultilineText();
      // });
    }, 500);
  },
  methods: {
    runAnimations() {
      const prospect_bar_linearGradient = this.$refs.prospectBarGradient;
      const linearGradientStops = getGradientStopsFromSVG(
        prospect_bar_linearGradient
      );

      const prospect_bar_mask = this.$refs.prospectMask;
      const prospect_bar_mask_x2 = prospect_bar_mask.getAttribute("x2");

      const prospect_graph_location =
        (parseInt(prospect_bar_mask_x2) * this.prospectScore) / 100;
      const prospect_avatar = this.$refs.prospectAvatar;
      const prospect_avatar_width = prospect_avatar.getBBox().width;
      const prospect_score_text = this.$refs.prospectScoreValue;
      const current_risk_text = this.$refs.currentRiskText;
      current_risk_text.innerHTML = this.currentRiskText;

      this.prospectAvatarRingColor = getColorAtGradientOffset(
        linearGradientStops,
        (100 - this.prospectScore) / 100
      );

      const improved_prospect_score = Math.round(this.prospectScore * 0.33);
      const improved_prospect_graph_location =
        (parseInt(prospect_bar_mask_x2) * improved_prospect_score) / 100;
      const improved_prospect_avatar = this.$refs.improvedProspectAvatar;
      const improved_prospect_avatar_width =
        improved_prospect_avatar.getBBox().width;
      const improved_prospect_score_text =
        this.$refs.improvedProspectScoreValue;
      const improved_risk_text = this.$refs.improvedRiskText;
      improved_risk_text.innerHTML = this.improvedRiskText;

      this.improvedProspectAvatarRingColor = getColorAtGradientOffset(
        linearGradientStops,
        (100 - improved_prospect_score) / 100
      );

      gsap.to([prospect_bar_mask], {
        attr: {
          x1: prospect_graph_location,
        },
        duration: this.duration,
        ease: this.ease,
      });
      gsap.to([prospect_avatar], {
        opacity: 1,
        duration: 0.5,
        ease: this.ease,
      });
      gsap.to([prospect_avatar], {
        x: prospect_graph_location - prospect_avatar_width / 2,
        duration: this.duration,
        ease: this.ease,
      });
      const prospectValueTarget = { val: 0 };
      gsap.to(
        prospectValueTarget,
        {
          delay: 1,
          val: this.prospectScore,
          duration: this.duration + 0.45,
          ease: this.ease,
          onUpdate: function () {
            prospect_score_text.innerHTML =
              prospectValueTarget.val.toFixed(0) + "%";
          },
        },
        0
      );
      /// IMPROVED PROSPECT AVATAR
      gsap.to([improved_prospect_avatar], {
        opacity: 1,
        duration: 0.5,
        ease: this.ease,
      });
      gsap.to([improved_prospect_avatar], {
        x:
          improved_prospect_graph_location - improved_prospect_avatar_width / 2,
        duration: this.duration,
        ease: this.ease,
      });
      const improvedProspectValueTarget = { val: 0 };
      gsap.to(
        improvedProspectValueTarget,
        {
          delay: 1,
          val: improved_prospect_score,
          duration: this.duration + 0.45,
          ease: this.ease,
          onUpdate: function () {
            improved_prospect_score_text.innerHTML =
              improvedProspectValueTarget.val.toFixed(0) + "%";
          },
        },
        0
      );
      gsap.to([improved_risk_text], {
        delay: 1,
        opacity: 1,
        duration: 0.5,
        ease: this.ease,
      });
      gsap.to([current_risk_text], {
        delay: 1,
        opacity: 1,
        duration: 0.5,
        ease: this.ease,
      });
    },

    setSvgMultilineText() {
      var y = 12;
      // const textColor = this.pdfType === "print" ? "#000" : "#FFF";

      const textAdjust = (element, textContent, boxWidth) => {
        if (!element) return;

        const width = boxWidth ? boxWidth : "200"; // element.getBBox().width + 90;
        /* split the words into array */
        const words = textContent.split(" ");
        let line = "";

        /* Make a tspan for testing */
        element.innerHTML = "";
        element.innerHTML =
          '<tspan fill="#fff" class="processing">loading</tspan>';
        for (let n = 0; n < words.length; n++) {
          const testLine = line + words[n] + " ";
          const testElem = document.querySelector(".processing"); // document.getElementById("PROCESSING");

          /*  Add line in testElement */
          testElem.innerHTML = testLine;
          /* Measure textElement */
          const metrics = testElem.getBoundingClientRect();
          const testWidth = metrics.width;

          if (testWidth > width && n > 0) {
            element.innerHTML += `<tspan x="${
              width * 0.125
            }" dy="${y}" class="bold-text" text-anchor="middle">vv${line}</tspan>`;
            // '<tspan fill="#fff" x="0" dy="' + y + '">' + line + "</tspan>";
            line = words[n] + " ";
          } else {
            line = testLine;
          }
        }

        this.$nextTick(() => {
          element.innerHTML += `<tspan x="${0}" dy="${y}" class="bold-text" text-anchor="middle">ss${line}</tspan>`;
        });

        // element.innerHTML += `<tspan x="${
        //   width * 0.125
        // }" dy="${y}" class="bold-text" text-anchor="middle" >${line}</tspan>`;

        document.querySelector(".processing").remove();
      };

      // let mq1 = window.matchMedia("(min-width: 351px) and (max-width: 479px)");

      // let mq2 = window.matchMedia("(min-width: 480px) and (max-width: 549px)");

      // let mq3 = window.matchMedia("(min-width: 550px) and (max-width: 599px)");

      // let mq4 = window.matchMedia("(min-width: 600px) and (max-width: 1263px)");

      // let mq5 = window.matchMedia("(min-width: 1264px)");

      // let textBoxWidth = 100;

      // if (mq1.matches) {
      //   textBoxWidth = 150;
      // } else if (mq2.matches) {
      //   textBoxWidth = 175;
      // } else if (mq3.matches || this.pdfType) {
      //   textBoxWidth = 200;
      // } else if (mq4.matches) {
      //   textBoxWidth = 250;
      // } else if (mq5.matches) {
      //   textBoxWidth = 300;
      // }
      // console.log(" this.$refs.currentRiskText,", this.$refs.currentRiskText);
      textAdjust(
        this.$refs.currentRiskText,
        this.currentRiskText,
        this.$refs.improvedProspectAvatarBox.getBoundingClientRect().width - 40
      );
      textAdjust(
        this.$refs.improvedRiskText,
        this.improvedRiskText,
        this.$refs.improvedProspectAvatarBox.getBoundingClientRect().width - 40
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.prospectMask {
  stroke: #274770;
  opacity: 0;
}
.rs-improve {
  padding: 0 2%;
  position: relative;

  &__svg {
    overflow: visible;
  }

  .percentages {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
</style>
